<template>
    <div class="content">
        <BreadCrumbs
            :elements="[
                { link: 'Cart', name: 'Kosár' },
                { link: 'CheckoutBilling', name: 'Számlázási adatok' },
                { link: 'CheckoutShipping', name: 'Szállítási mód' }
            ]"
        />
        <CartHeader />
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <h1 class="title text-center">Szálítási mód</h1>
                        <form v-on:submit.prevent="nextStep">
                            <div class="my-4">
                                <label
                                    class="d-block"
                                    v-for="(item, index) in model"
                                    v-bind:key="'shipping-' + index"
                                >
                                    <input
                                        required
                                        type="radio"
                                        name="shipping"
                                        :value="item.id"
                                        v-model="form.shipping"
                                        v-bind:checked="index === 0"
                                    />
                                    {{ item.name }}
                                    <template v-if="parseInt(item.price) === 0">
                                        Ingyenes
                                    </template>
                                    <template
                                        v-if="
                                            item.discount_from !== null &&
                                                item.discount_to !== null &&
                                                item.discount_from !==
                                                    '0000-00-00 00:00:00' &&
                                                item.discount_to !==
                                                    '0000-00-00 00:00:00' &&
                                                item.discount_from <= dateNow &&
                                                item.discount_to >= dateNow
                                        "
                                    >
                                        ({{ item.discount_price }} Ft / db)
                                    </template>
                                    <template v-else>
                                        ({{ item.price }} Ft / db)
                                    </template>
                                </label>
                            </div>

                            <div class="row">
                                <div class="col-md-8">
                                    <router-link
                                        :to="'/'"
                                        class="text-grey d-block"
                                        >Vásárlás folytatása</router-link
                                    >
                                </div>
                                <div class="col-md-4 text-end">
                                    <button
                                        type="submit"
                                        class="btn btn-primary w-100 mb-2"
                                    >
                                        Tovább
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BreadCrumbs from "@/components/BreadCrumbs";
import CartHeader from "@/components/CartHeader";

export default {
    name: "CheckoutShipping",
    components: { CartHeader, BreadCrumbs },
    data() {
        return {
            model: {},
            form: {
                shipping: null
            }
        };
    },
    beforeMount() {
        if (!$cookies.get("billing")) {
            this.$router.push({ name: "CheckoutBilling" });
        }
        this.getShipping();
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        },
        dateNow: function() {
            let yourDate = new Date();
            return yourDate.toISOString().split("T")[0];
        }
    },
    methods: {
        getShipping() {
            ApiService.get(`${this.url}/shipping/get`)
                .then(response => {
                    this.model = response.data.data;
                    this.form.shipping = this.model[0].id;
                })
                .finally(() => {
                    if ($cookies.get("shipping")) {
                        this.form.shipping = $cookies.get("shipping");
                    }
                });
        },
        nextStep() {
            const cartUuid = $cookies.get("cart_uuid");
            ApiService.post(
                `${this.url}/cart/shipping?uuid=${cartUuid}&shipping=${this.form.shipping}`
            ).then(() => {
                $cookies.set("shipping", this.form.shipping, 86400);
                this.$router.push({ name: "CheckoutPayment" });
            });
        }
    }
};
</script>

<style scoped></style>
