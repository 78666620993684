var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content pt-0"},[_c('section',{staticClass:"tyre-bg"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-12 text-center"},[(
                            _vm.simpleData &&
                                _vm.simpleData.result &&
                                (_vm.simpleData.result.e === 'SUCCESS' ||
                                    _vm.simpleData.result.is_success)
                        )?[_c('h1',{staticClass:"title mb-4"},[_vm._v("Sikeres rendelés")]),_vm._m(0),_c('p',{domProps:{"innerHTML":_vm._s(_vm.simpleData.result_formatted)}})]:[_c('h1',{staticClass:"title mb-4"},[_vm._v("Sikertelen rendelés")]),(
                                _vm.simpleData &&
                                    _vm.simpleData.result &&
                                    _vm.simpleData.result.e === 'TIMEOUT'
                            )?_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t( "Túllépte a tranzakció maximális idő limitét." ))+" ")]):_vm._e(),(
                                _vm.simpleData &&
                                    _vm.simpleData.result &&
                                    _vm.simpleData.result.e === 'CANCEL'
                            )?_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("Megszakított fizetés."))+" ")]):_vm._e(),(
                                _vm.simpleData.result &&
                                    _vm.simpleData.result.e !== 'CANCEL'
                            )?_c('p',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.simpleData.result_formatted)}}):_vm._e()],_c('router-link',{attrs:{"to":'/'}},[_vm._v("Vissza a főoldalra")])],2)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Sikeresen leadta rendelését! "),_c('br'),_vm._v(" A megrendelésével kapcsolatos részleteket, a megrendelés során megadot e-mail címére fogjuk elküldeni. ")])}]

export { render, staticRenderFns }