var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('BreadCrumbs',{attrs:{"elements":[
            { link: 'Cart', name: 'Kosár' },
            { link: 'CheckoutBilling', name: 'Számlázási adatok' },
            { link: 'CheckoutShipping', name: 'Szállítási mód' }
        ]}}),_c('CartHeader'),_c('section',{staticClass:"py-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 offset-md-3"},[_c('h1',{staticClass:"title text-center"},[_vm._v("Szálítási mód")]),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.nextStep.apply(null, arguments)}}},[_c('div',{staticClass:"my-4"},_vm._l((_vm.model),function(item,index){return _c('label',{key:'shipping-' + index,staticClass:"d-block"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.shipping),expression:"form.shipping"}],attrs:{"required":"","type":"radio","name":"shipping"},domProps:{"value":item.id,"checked":index === 0,"checked":_vm._q(_vm.form.shipping,item.id)},on:{"change":function($event){return _vm.$set(_vm.form, "shipping", item.id)}}}),_vm._v(" "+_vm._s(item.name)+" "),(parseInt(item.price) === 0)?[_vm._v(" Ingyenes ")]:_vm._e(),(
                                        item.discount_from !== null &&
                                            item.discount_to !== null &&
                                            item.discount_from !==
                                                '0000-00-00 00:00:00' &&
                                            item.discount_to !==
                                                '0000-00-00 00:00:00' &&
                                            item.discount_from <= _vm.dateNow &&
                                            item.discount_to >= _vm.dateNow
                                    )?[_vm._v(" ("+_vm._s(item.discount_price)+" Ft / db) ")]:[_vm._v(" ("+_vm._s(item.price)+" Ft / db) ")]],2)}),0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('router-link',{staticClass:"text-grey d-block",attrs:{"to":'/'}},[_vm._v("Vásárlás folytatása")])],1),_vm._m(0)])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-4 text-end"},[_c('button',{staticClass:"btn btn-primary w-100 mb-2",attrs:{"type":"submit"}},[_vm._v(" Tovább ")])])}]

export { render, staticRenderFns }