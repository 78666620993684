var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('ul',{staticClass:"cart-steps"},[_c('li',{class:{ active: _vm.$route.name === 'Cart' }},[_c('div',{staticClass:"step-number"},[_vm._v("1")]),_c('div',{staticClass:"step-text"},[_vm._v("Kosár")]),_c('router-link',{staticClass:"stretched-link",attrs:{"to":{ name: 'Cart' }}})],1),_c('li',{class:{
                        active: _vm.$route.name === 'CheckoutBilling'
                    }},[_c('div',{staticClass:"step-number"},[_vm._v("2")]),_c('div',{staticClass:"step-text"},[_vm._v("Számlázási adatok")]),_c('router-link',{staticClass:"stretched-link",attrs:{"to":{ name: 'CheckoutBilling' }}})],1),_c('li',{class:{
                        active: _vm.$route.name === 'CheckoutShipping'
                    }},[_c('div',{staticClass:"step-number"},[_vm._v("3")]),_c('div',{staticClass:"step-text"},[_vm._v("Szállítási mód")]),_c('router-link',{staticClass:"stretched-link",attrs:{"to":{ name: 'CheckoutShipping' }}})],1),_c('li',{class:{
                        active: _vm.$route.name === 'CheckoutPayment'
                    }},[_c('div',{staticClass:"step-number"},[_vm._v("4")]),_c('div',{staticClass:"step-text"},[_vm._v("Fizetési mód")]),_c('router-link',{staticClass:"stretched-link",attrs:{"to":{ name: 'CheckoutPayment' }}})],1),_c('li',{class:{
                        active: _vm.$route.name === 'CheckoutFinish'
                    }},[_c('div',{staticClass:"step-number"},[_vm._v("5")]),_c('div',{staticClass:"step-text"},[_vm._v("Rendelés áttekintése")]),_c('router-link',{staticClass:"stretched-link",attrs:{"to":{ name: 'CheckoutFinish' }}})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }