<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <ul class="cart-steps">
                    <li v-bind:class="{ active: $route.name === 'Cart' }">
                        <div class="step-number">1</div>
                        <div class="step-text">Kosár</div>
                        <router-link
                            :to="{ name: 'Cart' }"
                            class="stretched-link"
                        ></router-link>
                    </li>
                    <li
                        v-bind:class="{
                            active: $route.name === 'CheckoutBilling'
                        }"
                    >
                        <div class="step-number">2</div>
                        <div class="step-text">Számlázási adatok</div>
                        <router-link
                            :to="{ name: 'CheckoutBilling' }"
                            class="stretched-link"
                        ></router-link>
                    </li>
                    <li
                        v-bind:class="{
                            active: $route.name === 'CheckoutShipping'
                        }"
                    >
                        <div class="step-number">3</div>
                        <div class="step-text">Szállítási mód</div>
                        <router-link
                            :to="{ name: 'CheckoutShipping' }"
                            class="stretched-link"
                        ></router-link>
                    </li>
                    <li
                        v-bind:class="{
                            active: $route.name === 'CheckoutPayment'
                        }"
                    >
                        <div class="step-number">4</div>
                        <div class="step-text">Fizetési mód</div>
                        <router-link
                            :to="{ name: 'CheckoutPayment' }"
                            class="stretched-link"
                        ></router-link>
                    </li>
                    <li
                        v-bind:class="{
                            active: $route.name === 'CheckoutFinish'
                        }"
                    >
                        <div class="step-number">5</div>
                        <div class="step-text">Rendelés áttekintése</div>
                        <router-link
                            :to="{ name: 'CheckoutFinish' }"
                            class="stretched-link"
                        ></router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CartHeader"
};
</script>

<style scoped></style>
