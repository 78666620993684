<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <ul class="breadcrumbs">
                    <li><router-link :to="'/'">Főoldal</router-link></li>
                    <li
                        v-for="(item, index) in elements"
                        v-bind:key="'breadcrumb-' + index"
                    >
                        <router-link :to="{ name: item.link }">{{
                            item.name
                        }}</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BreadCrumbs",
    props: ["elements"]
};
</script>

<style scoped></style>
