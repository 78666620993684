<template>
    <div class="content">
        <BreadCrumbs
            :elements="[
                { link: 'Cart', name: 'Kosár' },
                { link: 'CheckoutBilling', name: 'Számlázási adatok' },
                { link: 'CheckoutShipping', name: 'Szállítási mód' },
                { link: 'CheckoutPayment', name: 'Fizetési mód' }
            ]"
        />
        <CartHeader />
        <section class="py-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <h1 class="title text-center">Fizetési mód</h1>
                        <form v-on:submit.prevent="setPayment">
                            <div class="my-4">
                                <label
                                    class="d-block"
                                    v-for="(item, index) in model"
                                    v-bind:key="'payment-' + index"
                                >
                                    <input
                                        type="radio"
                                        name="payment"
                                        :value="item.id"
                                        v-model="form.payment"
                                        v-bind:checked="index === 0"
                                    />
                                    {{ item.name }}
                                    <template v-if="parseInt(item.price) !== 0">
                                        ({{ item.price }} Ft)
                                    </template>
                                </label>
                            </div>
                            <div class="my-4">
                                <label>Kuponkód megadása</label>
                                <input
                                    type="text"
                                    name="coupon"
                                    v-model="form.coupon"
                                    class="form-control"
                                />
                            </div>

                            <div class="row">
                                <div class="col-md-8">
                                    <router-link
                                        :to="'/'"
                                        class="text-grey d-block"
                                        >Vásárlás folytatása</router-link
                                    >
                                </div>
                                <div class="col-md-4 text-end">
                                    <button
                                        type="submit"
                                        class="btn btn-primary w-100 mb-2"
                                    >
                                        Tovább
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BreadCrumbs from "@/components/BreadCrumbs";
import CartHeader from "@/components/CartHeader";

export default {
    name: "CheckoutPayment",
    components: { CartHeader, BreadCrumbs },
    data() {
        return {
            model: {},
            form: {
                payment: null,
                coupon: null
            }
        };
    },
    beforeMount() {
        if (!$cookies.get("billing")) {
            this.$router.push({ name: "Cart" });
        }
        if (!$cookies.get("shipping")) {
            this.$router.push({ name: "CheckoutShipping" });
        }
        this.getPayments();
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        },
        dateNow: function() {
            let yourDate = new Date();
            return yourDate.toISOString().split("T")[0];
        }
    },
    methods: {
        getPayments() {
            ApiService.get(`${this.url}/payment/get`)
                .then(response => {
                    this.model = response.data.data;
                    this.form.payment = this.model[0].id;
                })
                .finally(() => {
                    if ($cookies.get("payment")) {
                        this.form.payment = $cookies.get("payment");
                    }
                });
        },
        setPayment() {
            const cartUuid = $cookies.get("cart_uuid");
            const self = this;
            ApiService.post(`${self.url}/validate-coupon`, {
                code: self.form.coupon
            }).then(() => {
                ApiService.post(
                    `${self.url}/cart/payment?uuid=${cartUuid}&payment=${this.form.payment}`
                ).then(() => {
                    $cookies.set("payment", self.form.payment, 86400);
                    self.$router.push({ name: "CheckoutFinish" });
                });
            });
        }
    }
};
</script>

<style scoped></style>
